import React, { Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
function initialState() {
  return {
    visao: "",
    contatoEdicao: {
      tipo: "",
      permissao: false
    }
  };
}

const DetalhesLead = ({
  defineDetalhesObjeto = () => { },
  lead = {},
  contatos = [],
  definirPermissaoContato = () => { },

}) => {

  function modificarPermissao(event) {
    const { id, checked } = event.target;
    definirPermissaoContato(id, checked)
  }

  return (
    <Fragment>
      <div className="default-title">Lead</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          {lead.leadNome ? (
            <div className="col-md-6">
              <div className="font-weight-bold">
                Nome:{" "}
                <span className="font-weight-normal">
                  {lead.leadNome}
                </span>
              </div>
            </div>
          ) : null}
          {lead.leadNumDocumento ? (
            <div className="col-md-3">
              <div className="font-weight-bold">
                Documento:{" "}
                <span className="font-weight-normal">
                  {lead.leadNumDocumento}
                </span>
              </div>
            </div>
          ) : null}

          {lead.leadDataCadastro ? (
            <div className="col-md-3">
              <div className="font-weight-bold">
                Data Criação:{" "}
                <span className="font-weight-normal">
                  {lead.leadDataCadastro}
                </span>
              </div>
            </div>
          ) : null}
          {lead.leadObservacaoLead ? (
            <div className="col-md-12">
              <div className="font-weight-bold">
                Observação:{" "}
                <span className="font-weight-normal">
                  {lead.leadObservacaoLead}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="default-title">Opções de Contato</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          <div className="col-8 font-weight-bold">
            Contato
          </div>
          <div className="col-4 font-weight-bold">
            Permite Contato?

          </div>
          <div className="col-12">
            <hr />
          </div>
        </div>

        {contatos.map((contato, index) => (

          <div className={index % 2 === 0 ? "row align-items-center zebraItem" : "row align-items-center"}>
            <div key={"cont_" + contato.id} className="col-8">
              <div className="font-weight-bold">
                <span className="font-weight-normal">
                  {contato.contato}
                </span>
              </div>
            </div>
            <div key={"perm_" + contato.id} className="col-4">
              <div key={"perm1_" + contato.id} style={{ paddingLeft: "5rem" }} className="custom-control custom-switch ">
                <input
                  checked={contato.permissao}
                  type="checkbox"
                  className="custom-control-input"
                  id={contato.id}
                  name={contato.id}
                  onChange={(event) => modificarPermissao(event)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={contato.id}
                ></label>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default DetalhesLead;
