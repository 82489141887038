/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react';
import AppContext from '../../../../Store/AppContext';
import { Listar as ListarFiltroDeDados } from '../../../../Services/FiltroDeDados';
import { Listar as ListarTemplateEmail } from "../../../../Services/TemplateEmail";
import { Listar as ListarTemplateSms } from "../../../../Services/TemplateSms";
import { Listar as ListarTemplateWhatsAppBlip } from "../../../../Services/TemplateWhatsAppBlip";
import { removeItemFromArray } from '../../../../Utils/globalFunctions';
import {
	Listar as ListarFluxoEventos,
	Detalhes as DetalhesFluxoEventos,
	Remover as RemoverFluxoEventos,
	Incluir as IncluirFluxoEventos,
	Atualizar as AtualizarFluxoEventos
} from '../../../../Services/FluxoEventos';
import { useHistory } from 'react-router-dom';
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina';
import MuralFluxoEventos from '../../../../Components/FluxoEventos/MuralFluxoEventos/MuralFluxoEventos';
export const FluxoEventosContext = createContext();

const tituloTela = 'Fluxo de Eventos';

const defaultData = {
	fluxoEventosNome: "",
	fluxoEventosDescricao: "",
	fluxoEventosAtivo: "A",
	fluxoEventosMinutosEntreExecucoes: 0,
	fluxoEventosInicioExecucaoData: "",
	fluxoEventosInicioExecucaoHora: "00:00",
	fluxoEventosFimExecucaoData: "",
	fluxoEventosFimExecucaoHora: "23:59",
	itens: []
}

const searchData = {
	fluxoEventosNome: ''
};

const columnsDefinition = [
	{
		text: 'Id',
		dataField: 'fluxoEventosId',
		hidden: true
	},
	{
		text: "Mural",
		dataField: "fluxoEventosId",
		headerStyle: { width: "100px" },
		sort: false,
		formatter: (cell, row) => {
			return <div style={{ width: "100%", textAlign: "center" }} ><MuralFluxoEventos CssClass="btn btn-primary" fluxoEventosId={cell} /></div>
		},
	},
	{
		text: 'Nome',
		dataField: 'fluxoEventosNome',
		headerStyle: { width: '140px' },
		sort: true
	},
	{
		text: 'Descrição',
		dataField: 'fluxoEventosDescricao',
		headerStyle: { width: '250px' },
		sort: true
	},
	{
		text: 'Intervalo',
		dataField: 'fluxoEventosMinutosEntreExecucoes',
		headerStyle: { width: '70px' },
		sort: true,
		formatter: (cell, row) => {
			return cell + (cell <= 1 ? ' minuto' : ' minutos');
		},
	},
	{
		text: "Status",
		dataField: "fluxoEventosAtivo",
		headerStyle: { width: "60px" },
		sort: true,
		formatter: (cell, row) => {
			return cell === "A" ? (
				<span className="badge badge-success">Ativo</span>
			) : (
				<span className="badge badge-danger">Inátivo</span>
			);
		},
	},

];

const columnsDefinitionItens = [
	{
		text: 'Id',
		dataField: 'fluxoEventosItensId',
		hidden: true
	},
	{
		text: 'Tipo',
		dataField: 'fluxoEventosItensTipo',
		headerStyle: { width: '75px' },
		sort: true
	},
	{
		text: 'Template',
		dataField: 'fluxoEventosItensTemplateIdDesc',
		headerStyle: { width: '75px' },
		sort: true
	},
	{
		text: 'Filtro de Dados',
		dataField: 'filtroDeDadosIdDesc',
		headerStyle: { width: '75px' },
		sort: true
	}
];


const defaultDataItens = {
	fluxoEventosItensId: 0,
	fluxoEventosId: 0,
	fluxoEventosItensTipo: "",
	fluxoEventosItensTemplateId: "",
	filtroDeDadosId: 0
};

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		columnsDefinition: columnsDefinition,
		data: defaultData,
		formDisabled: true,
		itens: [],
		filtrosDeDados: [],
		dataItens: defaultDataItens,
		columnsDefinitionItens: columnsDefinitionItens,
		fluxoEventosItens: [],
		templatesEmail: [],
		templatesSms: [],
		templatesWhatsAppBlip: []
	};
}

const FluxoEventosProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());
	let history = useHistory();

	async function loadDependences(complete = true) {
		const permissoesPagina = validarPermissoesPagina();
		let filtrosDeDados = [];
		let templatesEmail = [];
		let templatesSms = [];
		let templatesWhatsAppBlip = [];

		if (complete) {
			const responseListarFiltroDeDados = await ListarFiltroDeDados({});
			const responseListarTemplateEmail = await ListarTemplateEmail({});
			const responseListarTemplateSms = await ListarTemplateSms({});
			const responseListarTemplateWhatsAppBlip = await ListarTemplateWhatsAppBlip({});

			if (!responseListarFiltroDeDados.success
				|| !responseListarTemplateEmail.success
				|| !responseListarTemplateSms.success
				|| !responseListarTemplateWhatsAppBlip.success) {
				showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
				return { produtos: [] };
			}

			filtrosDeDados = await responseListarFiltroDeDados.filtrosDeDados;
			templatesEmail = await responseListarTemplateEmail.templatesEmail;
			templatesSms = await responseListarTemplateSms.templatesSms;
			templatesWhatsAppBlip = await responseListarTemplateWhatsAppBlip.templatesWhatsAppBlip;
		}
		return { permissoesPagina, filtrosDeDados, templatesEmail, templatesSms, templatesWhatsAppBlip };
	}

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				filtrosDeDados: await dependencias.filtrosDeDados
			});
			hideLoading();
		}
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListarFluxoEventos = await ListarFluxoEventos(values.searchData);

		if (!responseListarFluxoEventos.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}

		const resultSearchData = await responseListarFluxoEventos.fluxosEventos;
		resultSearchData.map((data) => {
			data.Id = data.fluxoEventosId;
			return data;
		});

		setValues({
			...values,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const removeHandler = async (id) => {
		showLoading();

		const responseRemover = await RemoverFluxoEventos(id);

		if (!responseRemover.success) {
			hideLoading();
			showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`);
			return;
		}
		searchHandler();
		hideLoading();
		showMessage('Sucesso', 'Registro excluido.');
	};

	const getDetailHandler = async (id) => {
		showLoading();
		const dependencias = await loadDependences();
		if (id > 0) {
			const responseDetalhes = await DetalhesFluxoEventos(id);

			const { success, fluxoEventos } = await responseDetalhes;

			if (!success) {
				showMessage('Erro', 'Erro ao buscar os detalhes do registro.');
				setValues({
					...values,
					data: defaultData,
					permissoesPagina: await dependencias.permissoesPagina,
					filtrosDeDados: [],
					templatesEmail: [],
					templatesSms: [],
					templatesWhatsAppBlip: []
				});
			}

			let filtrosDeDados = await dependencias.filtrosDeDados;
			let templatesEmail = await dependencias.templatesEmail;
			let templatesSms = await dependencias.templatesSms;
			let templatesWhatsAppBlip = await dependencias.templatesWhatsAppBlip;

			let fluxoEventosItens = fluxoEventos.itens;
			fluxoEventosItens.map((item) => {
				item.id = item.fluxoEventosItensId;
				item.filtroDeDadosIdDesc = filtrosDeDados.find(x => x.filtroDeDadosId === item.filtroDeDadosId).filtroDeDadosNome;
				if (item.fluxoEventosItensTipo == "EMAIL")
					item.fluxoEventosItensTemplateIdDesc = templatesEmail.find(x => x.templateEmailId === item.fluxoEventosItensTemplateId).templateEmailNome;
				if (item.fluxoEventosItensTipo == "SMS")
					item.fluxoEventosItensTemplateIdDesc = templatesSms.find(x => x.templateSmsId === item.fluxoEventosItensTemplateId).templateSmsNome;
				if (item.fluxoEventosItensTipo == "WHATSAPP")
					item.fluxoEventosItensTemplateIdDesc = templatesWhatsAppBlip.find(x => x.templateWhatsAppBlipId === item.fluxoEventosItensTemplateId).templateWhatsAppBlipNome;
				return item;
			});

			setValues({
				...values,
				data: { ...fluxoEventos },
				fluxoEventosItens: fluxoEventosItens,
				permissoesPagina: await dependencias.permissoesPagina,
				filtrosDeDados: filtrosDeDados,
				templatesEmail: templatesEmail,
				templatesSms: templatesSms,
				templatesWhatsAppBlip: templatesWhatsAppBlip
			});
		} else {
			setValues({
				...values,
				data: {
					...defaultData,
					fluxoEventosId: 0
				},
				formDisabled: false,
				permissoesPagina: await dependencias.permissoesPagina,
				filtrosDeDados: await dependencias.filtrosDeDados,
				templatesEmail: await dependencias.templatesEmail,
				templatesSms: await dependencias.templatesSms,
				templatesWhatsAppBlip: await dependencias.templatesWhatsAppBlip,
				fluxoEventosItens: []
			});
		}
		hideLoading();
	};

	const createData = async () => {
		showLoading();
		const responseIncluir = await IncluirFluxoEventos(values.data);
		if (!responseIncluir.success) {
			hideLoading();
			showMessage('Erro', `Erro ao criar o registro: ${responseIncluir.message}`);
			responseIncluir.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}

		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro criado.');
		history.goBack();
	};

	const editHandler = () => {
		setValues({
			...values,
			formDisabled: false
		});
	};

	const updateData = async () => {
		showLoading();
		const responseAtualizar = await AtualizarFluxoEventos(values.data);
		if (!responseAtualizar.success) {
			hideLoading();
			showMessage('Erro', `Erro ao atualizar o registro: ${responseAtualizar.message}`);
			responseAtualizar.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}
		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro atualizado.');
		history.goBack();
	};

	const saveHandler = async () => {
		if (values.data.fluxoEventosId === 0) {
			await createData();
		} else {
			await updateData();
		}
	};

	const cancelHandler = () => {
		setValues({
			...values,
			data: defaultData,
			formDisabled: true
		});
	};

	const adionarItem = () => {
		var fluxoEventosItensNew = values.fluxoEventosItens;
		var idTempotario = new Date().getTime();
		idTempotario = idTempotario & 0xffffffff;
		values.dataItens.fluxoEventosItensId = idTempotario;
		values.dataItens.id = parseInt(values.dataItens.fluxoEventosItensId);
		values.dataItens.fluxoEventosItensId = parseInt(values.dataItens.fluxoEventosItensId);
		fluxoEventosItensNew.push(values.dataItens);
		setValues({
			...values,
			dataItens: defaultDataItens,
			fluxoEventosItens: fluxoEventosItensNew,
			data: {
				...values.data,
				itens: fluxoEventosItensNew
			}
		});
	};

	const removerItem = (id) => {
		var fluxoEventosItensNew = values.fluxoEventosItens;
		removeItemFromArray(fluxoEventosItensNew, fluxoEventosItensNew.find((supervisor) => supervisor.id === id));
		setValues({
			...values,
			dataItens: defaultDataItens,
			fluxoEventosItens: fluxoEventosItensNew,
			data: {
				...values.data,
				itens: fluxoEventosItensNew
			}
		});
	};

	return (
		<FluxoEventosContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler,
				cancelHandler,
				removeHandler,
				saveHandler,
				getDetailHandler,
				editHandler,
				adionarItem,
				removerItem
			}}
		>
			{children}
		</FluxoEventosContext.Provider>
	);
};

export default FluxoEventosProvider;
